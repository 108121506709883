<template>
  <div class="position-relative blog-page">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="page-title w-100">
        Читайте <span>наш блог</span>
      </div>

      <div class="blog-main" :style="{ 'background-image': `url('./css/images/blog-main-back.png')` }">
        <div class="blog-main-date">
          09.11.2023
        </div>
        <div class="blog-main-title truncate-text">
          Искусственный интеллект: все грани возможностей
        </div>
        <div class="blog-main-btn">
          Читать полностью
        </div>
      </div>

      <div class="blog-articles">
        <div v-for="(article, id) in articles" class="blog-article box-shadow" :key="id">
          <div class="blog-article-img" :style="{ 'background-image': `url('./css/images/${article.img}')` }">

          </div>
          <div class="blog-article-date">
            {{article.date}}
          </div>
          <div class="blog-article-title truncate-text-3">
            {{article.title}}
          </div>
          <div class="blog-article-btn" @click="goTo(article.id)">
            Читать полностью
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Blog',
  data() {
    return {
      currentTab: "all",
      articles: [
        { id : 1, date: '09.11.2023', title: 'Ии в нашей жизни: 10 примеров применения', img: 'blog-img-1.png'},
        { id : 2, date: '09.11.2023', title: 'Общение с искусственным интеллектом: возможности и ограничения', img: 'blog-img-1.png'},
        { id : 3, date: '09.11.2023', title: 'Блокчейн: цепочка данных без центра', img: 'blog-img-1.png'},
        { id : 4, date: '09.11.2023', title: 'Нейросети для всех - от обработки языка до автоматизации процессов', img: 'blog-img-1.png'},
        { id : 5, date: '09.11.2023', title: 'Обустройство рабочего пространства', img: 'blog-img-1.png'},
      ]
    }
  },
  methods: {
    goTo(id) {
      this.$router.push(`/blog/${id}`)
    }
  },
}
</script>
<style>

</style>
